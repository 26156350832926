*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@font-face {
  font-family: 'AvenirNext';
  src: url("./fonts/avenir-next/avenir-next-regular.eot");
  src: url("./fonts/avenir-next/avenir-next-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/avenir-next/avenir-next-regular.woff") format("woff"), url("./fonts/avenir-next/avenir-next-regular.ttf") format("truetype"), url("./fonts/avenir-next/avenir-next-regular.svg#AvenirNextLTW01RegularRegular") format("svg");
  font-weight: normal; }

@font-face {
  font-family: 'AvenirNext';
  src: url("./fonts/avenir-next/avenir-next-italic.eot");
  src: url("./fonts/avenir-next/avenir-next-italic.eot?#iefix") format("embedded-opentype"), url("./fonts/avenir-next/avenir-next-italic.woff") format("woff"), url("./fonts/avenir-next/avenir-next-italic.ttf") format("truetype"), url("./fonts/avenir-next/avenir-next-italic.svg#AvenirNextLTW01ItalicRegular") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'AvenirNext';
  src: url("./fonts/avenir-next/avenir-next-bold.eot");
  src: url("./fonts/avenir-next/avenir-next-bold.eot?#iefix") format("embedded-opentype"), url("./fonts/avenir-next/avenir-next-bold.woff") format("woff"), url("./fonts/avenir-next/avenir-next-bold.ttf") format("truetype"), url("./fonts/avenir-next/avenir-next-bold.svg#AvenirNextLTW01BoldRegular") format("svg");
  font-weight: bold; }

@font-face {
  font-family: 'AvenirNext';
  src: url("./fonts/avenir-next/avenir-next-thin.eot");
  src: url("./fonts/avenir-next/avenir-next-thin.eot?#iefix") format("embedded-opentype"), url("./fonts/avenir-next/avenir-next-thin.woff") format("woff"), url("./fonts/avenir-next/avenir-next-thin.ttf") format("truetype"), url("./fonts/avenir-next/avenir-next-thin.svg#AvenirNextW01ThinRegular") format("svg");
  font-weight: 300; }

@font-face {
  font-family: 'AvenirNext';
  src: url("./fonts/avenir-next/AvenirNext-DemiBold.eot");
  src: url("./fonts/avenir-next/AvenirNext-DemiBold.eot?#iefix") format("embedded-opentype"), url("./fonts/avenir-next/AvenirNext-DemiBold.woff") format("woff"), url("./fonts/avenir-next/AvenirNext-DemiBold.ttf") format("truetype");
  font-weight: 600; }

@font-face {
  font-family: 'AvenirNext';
  src: url("./fonts/avenir-next/AvenirNext-Bold.eot");
  src: url("./fonts/avenir-next/AvenirNext-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/avenir-next/AvenirNext-Bold.woff") format("woff"), url("./fonts/avenir-next/AvenirNext-Bold.ttf") format("truetype");
  font-weight: bold; }

body {
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1.75; }

h1, h2, h3, h4, h5, h6 {
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600; }

body, html {
  height: 100%; }

#root {
  height: 100%; }

.container {
  width: 94%;
  margin: 0 auto;
  max-width: 75rem; }

.content-card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 1px 17px 4px rgba(0, 0, 0, 0.03);
  padding: 2rem; }
  .content-card_title {
    color: #333;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0; }
  .content-card_subtitle {
    color: #697689;
    font-size: 0.75rem;
    letter-spacing: 0.00063rem; }
  .content-card_brandAffinity-select-text {
    margin-top: 4rem; }
    .content-card_brandAffinity-select-text div {
      margin-bottom: 50px;
      margin-top: -10px; }

.screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

.grey-background {
  background-color: #f3f3f3; }

.FootfallInsights {
  display: grid;
  grid-template-columns: 19.375rem 1fr;
  grid-template-rows: 5rem 1fr;
  -ms-grid-columns: 19.375rem 1fr;
  -ms-grid-rows: 5rem 1fr;
  min-height: 100vh;
  background-color: #FFF; }
  .FootfallInsights_special {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
  .FootfallInsights_main {
    position: relative; }
    .FootfallInsights_main_full-width {
      grid-column: 1 / 3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3; }
      .FootfallInsights_main_full-width .Insights_title-bar {
        margin-left: 2rem; }
    .FootfallInsights_main_open-side-bar-button {
      display: block;
      width: 3rem;
      height: 4rem;
      display: block;
      position: absolute;
      top: 2rem;
      left: 0;
      background-color: #415063;
      border: none;
      cursor: pointer;
      border-bottom-right-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
      .FootfallInsights_main_open-side-bar-button .SVGIcon {
        width: 1.5rem;
        height: 1.5rem;
        transform: rotate(180deg); }
        .FootfallInsights_main_open-side-bar-button .SVGIcon * {
          fill: #FFF; }
    .FootfallInsights_main_top {
      background-color: #f3f3f3;
      padding: 3rem 0 2rem;
      margin-bottom: 2rem; }
      .FootfallInsights_main_top_cards {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: top; }
        .FootfallInsights_main_top_cards .content-card {
          flex-grow: 1;
          text-align: center;
          padding: 2rem;
          margin-right: 2rem;
          font-size: 0.875rem;
          color: #333;
          line-height: 19px; }
          .FootfallInsights_main_top_cards .content-card .SVGIcon {
            width: 2rem;
            height: 2rem;
            display: block;
            margin: 0 auto 1rem; }
          .FootfallInsights_main_top_cards .content-card:last-child {
            margin-right: 0; }
        .FootfallInsights_main_top_cards_info {
          display: block;
          font-weight: bolder; }
  .FootfallInsights_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem; }
  .FootfallInsights_title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem; }
    .FootfallInsights_title-bar_left h1 {
      margin: 0 0 1rem 0;
      padding: 0;
      color: #30312F;
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: 0.00125rem;
      line-height: 1.5625rem; }
    .FootfallInsights_title-bar_left h3 {
      margin: 0;
      padding: 0;
      color: #697689;
      font-size: 0.75rem;
      font-weight: normal;
      letter-spacing: 0.00063rem;
      line-height: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 30rem; }
  .FootfallInsights_date-range-group {
    flex-grow: 1;
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 10; }
    .FootfallInsights_date-range-group_picker {
      height: 2.75rem;
      width: 8rem;
      border: 1px solid #D8D8D8;
      border-radius: 0.25rem;
      color: #4A4A4A;
      font-size: 0.75rem;
      line-height: 2.75rem;
      padding: 0 1rem;
      margin-left: 0.5rem;
      font-weight: 600;
      background-color: transparent;
      background-image: url(../images/date-picker-arrow.svg);
      background-position: 90% 50%;
      background-repeat: no-repeat;
      background-size: 0.625rem 0.375rem;
      user-select: none; }
      .FootfallInsights_date-range-group_picker_show-time {
        width: 10.625rem; }
      .FootfallInsights_date-range-group_picker_calendar ul {
        padding: 0 !important; }
      .FootfallInsights_date-range-group_picker_inner {
        clear: both;
        background-color: #f0f0f0;
        border-top: 1px solid #aeaeae;
        padding: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem; }
        .FootfallInsights_date-range-group_picker_inner_text {
          margin-right: 1rem; }
  .FootfallInsights_time-period-selection {
    width: 12rem; }
  .FootfallInsights_reports_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-gap: 1.5rem; }
    .FootfallInsights_reports_grid .content-card {
      box-shadow: 0 1px 28px 5px rgba(0, 0, 0, 0.06); }
    .FootfallInsights_reports_grid_top {
      grid-column: 1 / 3;
      grid-row: 1 / 2; }
    .FootfallInsights_reports_grid_left {
      grid-column: 1 / 2;
      grid-row: 2 / 3; }
    .FootfallInsights_reports_grid_right {
      grid-column: 2 / 3;
      grid-row: 2 / 3; }
    .FootfallInsights_reports_grid_middle {
      grid-column: 1 / 3;
      grid-row: 2 / 3; }
    .FootfallInsights_reports_grid_bottom {
      grid-column: 1 / 3;
      grid-row: 3 / 4; }
    .FootfallInsights_reports_grid_bottom-left {
      grid-column: 1 / 2;
      grid-row: 3 / 4; }
    .FootfallInsights_reports_grid_bottom-right {
      grid-column: 2 / 3;
      grid-row: 3 / 4; }
    .FootfallInsights_reports_grid_download {
      grid-column: 1 / 3;
      grid-row: 4 / 5;
      text-align: center;
      padding: 2rem 0; }
  .FootfallInsights_select {
    width: 12rem;
    margin-left: 0.5rem; }
  .FootfallInsights_DownlaodPdf {
    margin-top: 10px;
    margin-bottom: 10px; }
    .FootfallInsights_DownlaodPdf .DownloadCSVButton {
      margin: 10px  auto 10px auto; }

.Header {
  background-color: #2196F3;
  height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  grid-row: 1;
  grid-column: 1/3;
  position: relative;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.18); }
  .Header_nav {
    list-style-type: none; }
  .Header_logo {
    height: 2.5rem; }
  .Header_rigth {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px; }
    .Header_rigth a {
      padding: 1rem 3rem 1rem 1rem;
      color: #B3E5FC;
      cursor: pointer; }
  .Header_back-home-link {
    color: #697689;
    font-size: 0.6875rem;
    letter-spacing: 0.00063rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem; }
    .Header_back-home-link .SVGIcon {
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-right: 0.5rem; }
      .Header_back-home-link .SVGIcon * {
        fill: #32ABE1; }
  .Header_create-location-insights-button {
    color: #FFF;
    border: none;
    font-weight: 600;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: #32ABE1;
    border: none;
    padding: 0.625rem 1.75rem; }
  .Header_welcome-text {
    margin: 0 0.5rem 0 2rem;
    color: #697689;
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.00063rem;
    line-height: 1.125rem; }

.Sidebar {
  background-color: #415063;
  color: #1c232d;
  grid-row: 2;
  grid-column: 1;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  overflow: hidden;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.05);
  padding: 1rem 0; }
  .Sidebar_close-button {
    width: 2rem;
    height: 2rem;
    margin: 1rem 1rem 1.5rem;
    border: none;
    background: transparent;
    cursor: pointer; }
    .Sidebar_close-button:disabled {
      opacity: 0.5;
      cursor: not-allowed; }
    .Sidebar_close-button .SVGIcon {
      width: 1.5rem;
      height: 1.5rem; }
      .Sidebar_close-button .SVGIcon * {
        fill: #FFF; }
  .Sidebar_tab-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .Sidebar_tab {
    width: 50%;
    text-align: center;
    padding: 1rem;
    background-color: #384556;
    color: #d9e2f1;
    transition: all 0.2s;
    position: relative;
    font-weight: bold;
    font-size: 0.75rem;
    cursor: pointer;
    user-select: none; }
    .Sidebar_tab:after {
      content: " ";
      display: block;
      background-color: #599dc2;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1875rem;
      transform: scaleX(0);
      opacity: 0;
      transition: all 0.2s; }
    .Sidebar_tab_selected {
      background-color: #111926;
      color: #599dc2; }
      .Sidebar_tab_selected:after {
        transform: scaleX(1);
        opacity: 1; }
    .Sidebar_tab.react-tabs__tab--disabled {
      opacity: 0.5;
      cursor: default; }
  .Sidebar_save-parameters, .Sidebar_confirm-reset {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1.5rem; }
  .Sidebar_save-parameters_text {
    color: #D6DEE9;
    font-size: 0.75rem;
    letter-spacing: 0.00063rem;
    line-height: 1rem;
    margin-right: 2rem; }
  .Sidebar_save-parameters_switch {
    position: relative;
    display: inline-block;
    height: 1.5rem;
    width: 3rem;
    margin: 0;
    cursor: pointer; }
    .Sidebar_save-parameters_switch input {
      display: none; }
      .Sidebar_save-parameters_switch input:checked + .Sidebar_save-parameters_switch_slider {
        background-color: #32ABE1; }
        .Sidebar_save-parameters_switch input:checked + .Sidebar_save-parameters_switch_slider:before {
          transform: translateX(1.5rem); }
    .Sidebar_save-parameters_switch_slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1.5rem;
      width: 3rem;
      border-radius: 1rem;
      background-color: #384556;
      transition: 0.2s; }
      .Sidebar_save-parameters_switch_slider:before {
        position: absolute;
        content: "";
        height: 1.125rem;
        width: 1.125rem;
        left: 0.1875rem;
        bottom: 0.1875rem;
        background-color: white;
        transition: 0.2s;
        border-radius: 1rem; }
  .Sidebar_tab-panel {
    padding: 2rem 1.5rem 0; }
  .Sidebar_location-select-wrapper {
    padding: 0 1.5rem;
    margin-top: 1rem; }
  .Sidebar label {
    color: #D6DEE9;
    font-size: 0.75rem;
    letter-spacing: 0.00063rem; }
  .Sidebar_select, .Sidebar_select_organisation {
    margin-bottom: 1rem; }
    .Sidebar_select___control, .Sidebar_select_organisation___control {
      background-color: #384556 !important;
      border: none !important; }
    .Sidebar_select___value-container, .Sidebar_select___placeholder, .Sidebar_select___single-value, .Sidebar_select___input input, .Sidebar_select_organisation___value-container, .Sidebar_select_organisation___placeholder, .Sidebar_select_organisation___single-value, .Sidebar_select_organisation___input input {
      color: #D1D5DC !important; }
    .Sidebar_select___placeholder, .Sidebar_select_organisation___placeholder {
      color: #9B9B9B !important; }
    .Sidebar_select___dropdown-indicator svg *, .Sidebar_select_organisation___dropdown-indicator svg * {
      fill: #599dc2 !important; }
    .Sidebar_select___indicator-separator, .Sidebar_select_organisation___indicator-separator {
      background-color: #415063 !important;
      width: 2px !important; }
    .Sidebar_select___clear-indicator svg *, .Sidebar_select_organisation___clear-indicator svg * {
      fill: #9B9B9B !important; }
    .Sidebar_select_--is-disabled, .Sidebar_select_organisation_--is-disabled {
      opacity: 0.6; }
    .Sidebar_select___control--is-focused, .Sidebar_select_organisation___control--is-focused {
      box-shadow: 0px 0px 0px 1px #599dc2 !important; }
    .Sidebar_select___multi-value, .Sidebar_select_organisation___multi-value {
      background-color: rgba(65, 80, 98, 0.4) !important;
      font-size: 0.875rem !important;
      padding: 0.25rem !important; }
      .Sidebar_select___multi-value__label, .Sidebar_select_organisation___multi-value__label {
        color: #D8D8D8 !important; }
      .Sidebar_select___multi-value .selected-value, .Sidebar_select_organisation___multi-value .selected-value {
        display: inline-block;
        font-size: 12px;
        margin-right: 5px; }
      .Sidebar_select___multi-value .selected-belong-tag, .Sidebar_select_organisation___multi-value .selected-belong-tag {
        display: inline-block;
        color: #697689;
        font-size: 12px; }
  .Sidebar_select_organisation___dropdown-indicator, .Sidebar_select_organisation___indicator-separator {
    display: none !important; }
  .Sidebar_statistic-toggle {
    padding: 1rem 1.5rem;
    color: white;
    font-size: 14px; }
    .Sidebar_statistic-toggle_react-switch {
      margin-left: 4rem; }
    .Sidebar_statistic-toggle span {
      vertical-align: top; }
  .Sidebar_confirm-button {
    color: #FFF;
    border: none;
    font-weight: 600;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: #32ABE1;
    border: none;
    padding: 0;
    font-size: 0.75rem;
    height: 2.75rem;
    width: 7.625rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16); }
    .Sidebar_confirm-button:disabled {
      opacity: 0.5; }
  .Sidebar_reset-button {
    border: none;
    background: transparent;
    color: #32ABE1;
    height: 2.75rem; }

.Login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3; }
  .Login .content-card {
    width: 24rem;
    padding: 3rem 2.75rem; }
  .Login_logo {
    height: 4rem;
    margin-bottom: 1.5rem; }
  .Login label {
    font-size: 0.75rem;
    font-weight: 600;
    display: block;
    margin-bottom: 1.5rem;
    color: #697689; }
  .Login input {
    margin-top: 0.25rem;
    font-size: 1.25rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: solid 1px #d6dee9;
    width: 100%;
    display: block;
    width: 100%; }
  .Login_warning {
    font-weight: 600;
    color: #C00; }
  .Login_reset-password-title {
    font-size: 1.5rem; }
  .Login_password-reset-success {
    padding: 1.5rem;
    background-color: #afdef5;
    border-radius: 0.25rem;
    border: solid 1px #d6dee9; }
  .Login_link {
    display: block;
    margin-top: 1rem;
    color: #32ABE1; }
    .Login_link:hover {
      color: #4a9fc8; }

.CriteriaModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; }
  .CriteriaModal_background {
    background: rgba(0, 0, 0, 0.8); }
  .CriteriaModal_wrapper {
    background: #FFF;
    padding: 4rem;
    border-radius: 0.25rem;
    box-shadow: 0 1px 24px 4px rgba(0, 0, 0, 0.05); }
  .CriteriaModal_inner {
    position: relative; }
    .CriteriaModal_inner .SVGIcon {
      width: 2.5rem;
      height: 2.5rem;
      margin: 0 auto 2.25rem;
      display: block; }
  .CriteriaModal_close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    border: none;
    background: none; }
    .CriteriaModal_close-button .SVGIcon {
      width: 2rem;
      height: 2em; }
      .CriteriaModal_close-button .SVGIcon * {
        fill: #697689; }
  .CriteriaModal_title {
    text-align: center;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: #30312F;
    margin-bottom: 1rem;
    letter-spacing: 0.00125rem; }
  .CriteriaModal_label {
    color: #697689;
    font-size: 0.75rem;
    letter-spacing: 0.00063rem;
    line-height: 1rem;
    margin: 1.5rem 0 0.75rem; }
  .CriteriaModal_bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0 0;
    margin-bottom: 0; }
    .CriteriaModal_bottom-row_left {
      flex-grow: 1; }
  .CriteriaModal_middle-row select {
    font-size: 1.25rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: solid 1px #d6dee9;
    width: 100%; }
  .CriteriaModal_search-organisation-input, .CriteriaModal_subcategories-input, .CriteriaModal_report-input {
    flex-grow: 1;
    font-size: 1.25rem; }
    .CriteriaModal_search-organisation-input___value-container, .CriteriaModal_search-organisation-input___placeholder, .CriteriaModal_search-organisation-input___single-value, .CriteriaModal_search-organisation-input___input input, .CriteriaModal_subcategories-input___value-container, .CriteriaModal_subcategories-input___placeholder, .CriteriaModal_subcategories-input___single-value, .CriteriaModal_subcategories-input___input input, .CriteriaModal_report-input___value-container, .CriteriaModal_report-input___placeholder, .CriteriaModal_report-input___single-value, .CriteriaModal_report-input___input input {
      color: #415062 !important;
      font-size: 1rem !important; }
    .CriteriaModal_search-organisation-input_--is-disabled, .CriteriaModal_subcategories-input_--is-disabled, .CriteriaModal_report-input_--is-disabled {
      background-color: #F4F3F4; }
    .CriteriaModal_search-organisation-input___placeholder, .CriteriaModal_subcategories-input___placeholder, .CriteriaModal_report-input___placeholder {
      color: #9B9B9B !important;
      font-size: 1rem !important; }
    .CriteriaModal_search-organisation-input___option, .CriteriaModal_subcategories-input___option, .CriteriaModal_report-input___option {
      color: #4A4A4A !important;
      font-size: 0.875rem !important;
      position: relative; }
    .CriteriaModal_search-organisation-input___option--is-selected, .CriteriaModal_subcategories-input___option--is-selected, .CriteriaModal_report-input___option--is-selected {
      background-color: #FFF !important;
      color: #4A4A4A !important;
      font-weight: 600; }
    .CriteriaModal_search-organisation-input___option--is-focused, .CriteriaModal_subcategories-input___option--is-focused, .CriteriaModal_report-input___option--is-focused {
      background-color: rgba(50, 171, 225, 0.25) !important;
      color: #4A4A4A !important; }
    .CriteriaModal_search-organisation-input___control--is-focused, .CriteriaModal_subcategories-input___control--is-focused, .CriteriaModal_report-input___control--is-focused {
      box-shadow: 0px 0px 0px 1px #32ABE1, 0 1px 32px 4px rgba(0, 0, 0, 0.02) !important;
      border-color: #32ABE1 !important; }
  .CriteriaModal_search-organisation-input___dropdown-indicator, .CriteriaModal_search-organisation-input___indicator-separator {
    display: none !important; }
  .CriteriaModal_subcategories-input___option {
    padding-left: 2rem !important; }
    .CriteriaModal_subcategories-input___option:after {
      content: '';
      position: absolute;
      height: 1.125rem;
      width: 1.125rem;
      border: 1px solid #9B9B9B;
      border-radius: 50%;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      transition: border 0.2s; }
  .CriteriaModal_subcategories-input___option--is-selected:after {
    border: 5px solid #32ABE1; }
  .CriteriaModalGroupLabel {
    border: none;
    border-bottom: solid 1px #DADADA;
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
    background: #FFF; }
    .CriteriaModalGroupLabel_inner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem; }
    .CriteriaModalGroupLabel_label {
      color: #4A4A4A;
      font-size: 0.875rem; }
    .CriteriaModalGroupLabel_length {
      color: #9B9B9B;
      font-size: 0.75rem;
      font-weight: 600;
      margin-right: 0.75rem; }
  .CriteriaModal_search-button {
    font-size: 1.25rem;
    padding: 0.5rem 2rem;
    color: #FFF;
    background-color: #32ABE1;
    border-radius: 0.25rem;
    border: solid 1px #499fc7; }
    .CriteriaModal_search-button .SVGIcon {
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block; }
    .CriteriaModal_search-button:disabled {
      background-color: #CCC;
      border: none; }
  .CriteriaModal_select-account {
    overflow-y: scroll;
    max-height: 300px; }
    .CriteriaModal_select-account_account-item {
      background-color: white;
      border: 2px solid #f3f3f3;
      border-radius: 0.25rem;
      padding: 1rem;
      margin-bottom: 2rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, auto);
      cursor: pointer; }
      .CriteriaModal_select-account_account-item_selected {
        background-color: white;
        border: 2px solid #f3f3f3;
        border-radius: 0.25rem;
        padding: 1rem;
        margin-bottom: 2rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, auto);
        cursor: pointer;
        -webkit-box-shadow: 0 1px 17px 4px rgba(0, 0, 0, 0.03);
        box-shadow: 0 1px 17px 4px rgba(0, 0, 0, 0.03); }
    .CriteriaModal_select-account_label_text {
      display: inline-block;
      font-size: 1.125rem;
      font-weight: 600;
      color: #333;
      grid-column: 1; }
    .CriteriaModal_select-account_value {
      color: #697689;
      display: inline;
      font-weight: .875rem;
      width: 90%;
      grid-column: 2 / 4; }
    .CriteriaModal_select-account_radio {
      grid-column: 4;
      grid-row: 2;
      display: flex;
      align-items: center;
      justify-content: center; }

.modal-backdrop.show {
  opacity: 1; }

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
    width: 42rem;
    max-width: 90%; } }

.WhereYourCustomersHaveBeenGraph {
  width: 100%;
  min-height: 5rem;
  position: relative;
  /* end of _top-row*/ }
  .WhereYourCustomersHaveBeenGraph_barchart {
    height: 18.75rem; }
  .WhereYourCustomersHaveBeenGraph .Barchart_ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 100%; }
  .WhereYourCustomersHaveBeenGraph .Barchart_li {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    padding-top: 2rem;
    height: 100%; }
  .WhereYourCustomersHaveBeenGraph .Barchart_sharePerCent {
    color: #333333;
    font-size: 0.75rem;
    font-weight: 900;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: elipsis;
    margin-top: 0.25rem; }
  .WhereYourCustomersHaveBeenGraph .Barchart_affinity {
    color: #333333;
    font-size: 0.875rem;
    font-weight: 900;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: elipsis;
    margin-top: 0.25rem; }
    .WhereYourCustomersHaveBeenGraph .Barchart_affinity_affinity {
      font-size: 0.875rem; }
  .WhereYourCustomersHaveBeenGraph .Barchart_statistic-container {
    min-height: 28px; }
  .WhereYourCustomersHaveBeenGraph .Barchart_statistic {
    color: #333333;
    font-size: 0.75rem;
    font-weight: 300;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: elipsis;
    margin-top: 0.25rem; }
  .WhereYourCustomersHaveBeenGraph .Barchart_name {
    color: #697689;
    font-size: 0.75rem;
    letter-spacing: 0.00063rem;
    text-align: center;
    width: 100%;
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 6rem; }
  .WhereYourCustomersHaveBeenGraph .Barchart_percentageChange {
    color: #697689;
    font-size: 0.6875rem;
    letter-spacing: 0.00063rem;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: elipsis; }
  .WhereYourCustomersHaveBeenGraph .Barchart_bars {
    flex-grow: 1;
    position: relative;
    width: 0.5rem; }
  .WhereYourCustomersHaveBeenGraph .Barchart_bar-wrapper {
    top: 0;
    bottom: 0;
    display: block;
    position: absolute;
    width: 0.5rem; }
  .WhereYourCustomersHaveBeenGraph .Barchart_bar_last-month, .WhereYourCustomersHaveBeenGraph .Barchart_bar_this-month {
    width: 0.5rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: block;
    position: absolute;
    bottom: 0; }
  .WhereYourCustomersHaveBeenGraph .Barchart_bar_last-month {
    background-color: rgba(209, 213, 220, 0.65); }
  .WhereYourCustomersHaveBeenGraph .Barchart_bar_this-month {
    background-color: #2DE6D5; }
  .WhereYourCustomersHaveBeenGraph .Barchart_bar_this-month_negative {
    background-color: red;
    width: 0.5rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: block;
    position: absolute;
    bottom: 0; }
  .WhereYourCustomersHaveBeenGraph_inner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1; }
  .WhereYourCustomersHaveBeenGraph_top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    /* end of _button-group*/
    /* end of _select*/ }
    .WhereYourCustomersHaveBeenGraph_top-row_text {
      flex-grow: 1; }
    .WhereYourCustomersHaveBeenGraph_top-row_showing {
      color: #697689;
      font-size: 0.6875rem;
      letter-spacing: 0.00063rem;
      margin: 0 2rem; }
    .WhereYourCustomersHaveBeenGraph_top-row_button-group {
      display: inline-block; }
      .WhereYourCustomersHaveBeenGraph_top-row_button-group button {
        margin: 0;
        padding: 0;
        width: 2.125rem;
        height: 2.125rem;
        border: 1px solid #D1D5DC;
        background-color: #F6F6F6; }
        .WhereYourCustomersHaveBeenGraph_top-row_button-group button:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px; }
        .WhereYourCustomersHaveBeenGraph_top-row_button-group button:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-left: none; }
        .WhereYourCustomersHaveBeenGraph_top-row_button-group button:disabled {
          opacity: 0.5;
          cursor: not-allowed; }
        .WhereYourCustomersHaveBeenGraph_top-row_button-group button .SVGIcon {
          width: 1.5rem;
          height: 1.5rem; }
          .WhereYourCustomersHaveBeenGraph_top-row_button-group button .SVGIcon * {
            fill: #444; }
    .WhereYourCustomersHaveBeenGraph_top-row_select, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation {
      margin-bottom: 2rem;
      width: 13rem;
      margin-right: 1rem; }
      .WhereYourCustomersHaveBeenGraph_top-row_select___control, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___control {
        background-color: #384556 !important;
        border: none !important; }
      .WhereYourCustomersHaveBeenGraph_top-row_select___value-container, .WhereYourCustomersHaveBeenGraph_top-row_select___placeholder, .WhereYourCustomersHaveBeenGraph_top-row_select___single-value, .WhereYourCustomersHaveBeenGraph_top-row_select___input input, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___value-container, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___placeholder, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___single-value, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___input input {
        color: #D1D5DC !important; }
      .WhereYourCustomersHaveBeenGraph_top-row_select___placeholder, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___placeholder {
        color: #9B9B9B !important; }
      .WhereYourCustomersHaveBeenGraph_top-row_select___dropdown-indicator svg *, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___dropdown-indicator svg * {
        fill: #599dc2 !important; }
      .WhereYourCustomersHaveBeenGraph_top-row_select___indicator-separator, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___indicator-separator {
        background-color: #415063 !important;
        width: 2px !important; }
      .WhereYourCustomersHaveBeenGraph_top-row_select___clear-indicator svg *, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___clear-indicator svg * {
        fill: #9B9B9B !important; }
      .WhereYourCustomersHaveBeenGraph_top-row_select_--is-disabled, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation_--is-disabled {
        opacity: 0.6; }
      .WhereYourCustomersHaveBeenGraph_top-row_select___control--is-focused, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___control--is-focused {
        box-shadow: 0px 0px 0px 1px #599dc2 !important; }
      .WhereYourCustomersHaveBeenGraph_top-row_select___multi-value, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___multi-value {
        background-color: rgba(65, 80, 98, 0.4) !important;
        font-size: 0.875rem !important;
        padding: 0.25rem !important; }
        .WhereYourCustomersHaveBeenGraph_top-row_select___multi-value__label, .WhereYourCustomersHaveBeenGraph_top-row_select_organisation___multi-value__label {
          color: #D8D8D8 !important; }

.Loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 8rem; }
  .Loading_inner {
    width: 4rem;
    height: 4rem;
    perspective: 25rem;
    position: relative; }
  .Loading_circle {
    width: 4rem;
    height: 4rem;
    background-color: #125b7b;
    top: 1.3rem;
    left: 0;
    border-radius: 50%;
    transform: rotateX(82.5deg) scale(0);
    position: absolute;
    animation: Loading_pin-circle-animation 2.5s infinite; }
  .Loading_location-pin-shadow {
    transform-origin: 50% 81.25%;
    position: absolute;
    animation: Loading_pin-shadow-animation 2.5s infinite;
    top: 0;
    left: 0;
    opacity: 0.3;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(0px) scale(1.2, 2); }
    .Loading_location-pin-shadow * {
      fill: #afcbd8; }
  .Loading_location-pin {
    transform-origin: 50% 81.25%;
    position: absolute;
    top: 0;
    left: 0;
    animation: Loading_pin-jump-animation 2.5s infinite; }
    .Loading_location-pin * {
      fill: #54BDEC; }

@keyframes Loading_pin-jump-animation {
  0%, 10%, 60%, 100% {
    transform: none; }
  15% {
    transform: rotateX(10deg) scale(1.2, 0.6);
    animation-timing-function: ease-out; }
  20% {
    transform: rotateX(0) scale(0.8, 1.3) translateY(-1.5rem);
    animation-timing-function: linear; }
  25% {
    transform: rotateX(0) scale(1) translateY(-3rem);
    animation-timing-function: ease-in; }
  30% {
    transform: translateY(-1.5rem) scale(1);
    animation-timing-function: linear; }
  35% {
    transform: rotateX(10deg) scale(1.6, 0.4);
    animation-timing-function: ease-out; }
  40% {
    transform: scale(0.8, 1.2); }
  45% {
    transform: scale(1.1, 0.9); }
  50% {
    transform: scale(0.95, 1.05); }
  55% {
    transform: scale(1.025, 0.9075); } }

@keyframes Loading_pin-shadow-animation {
  0%, 10%, 60%, 100% {
    opacity: 0.2;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(0px) scale(1.2, 2); }
  15% {
    opacity: 0.2;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(0px) scale(1.4, 1.6);
    animation-timing-function: ease-out; }
  25% {
    opacity: 0.1;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(2rem) scale(1.4, 2.4);
    animation-timing-function: ease-in; }
  30% {
    opacity: 0.15;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(1rem) scale(1.2, 2);
    animation-timing-function: linear; }
  35% {
    opacity: 0.2;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(0px) scale(1.92, 0.8);
    animation-timing-function: ease-out; }
  40% {
    opacity: 0.2;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(0px) scale(1.1, 2.1); }
  45% {
    opacity: 0.2;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(0px) scale(1.3, 1.9); }
  50% {
    opacity: 0.2;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(0px) scale(1.15, 2.05); }
  55% {
    opacity: 0.2;
    transform: skewX(-50deg) rotateX(82.5deg) translateX(2px) translateY(10px) translateZ(0px) scale(1.25, 1.975); } }

@keyframes Loading_pin-circle-animation {
  0%, 35% {
    transform: rotateX(82.5deg) scale(0); }
  55%, 100% {
    transform: rotateX(82.5deg) scale(3.25);
    animation-timing-function: ease-out;
    opacity: 0; } }

.Component_loading {
  height: 100%; }

.ProfileDropdown {
  height: 3rem;
  width: 3rem;
  position: relative; }
  .ProfileDropdown_logo {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 0.5rem 0.05rem; }
  .ProfileDropdown_profile-button {
    width: 3rem;
    height: 3rem;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer; }
    .ProfileDropdown_profile-button .SVGIcon {
      width: 1.375rem;
      height: 1.375rem;
      display: inline-block; }
      .ProfileDropdown_profile-button .SVGIcon * {
        fill: #444; }
    .ProfileDropdown_profile-button:hover, .ProfileDropdown_profile-button:focus, .ProfileDropdown_profile-button_active {
      outline: none; }
      .ProfileDropdown_profile-button:hover .SVGIcon *, .ProfileDropdown_profile-button:focus .SVGIcon *, .ProfileDropdown_profile-button_active .SVGIcon * {
        fill: #32ABE1; }
  .ProfileDropdown_ul {
    z-index: 15;
    list-style: none;
    margin: 0;
    padding: 0.5rem 0;
    background: #FFF;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 3rem;
    right: 0;
    width: 10rem; }
  .ProfileDropdown_nav-ul {
    z-index: 101;
    list-style: none;
    margin: 0;
    padding: 0.5rem 0;
    background: #FFF;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 3rem;
    right: 0; }
  .ProfileDropdown_nav {
    color: #B3E5FC;
    font-size: 0.8rem; }
    .ProfileDropdown_nav :hover {
      color: white; }
  .ProfileDropdown_drop-down-button {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    color: #4A4A4A;
    transition: background-color 0.2s;
    font-size: 0.875rem; }
    .ProfileDropdown_drop-down-button .SVGIcon {
      width: 0.8rem;
      height: 0.8rem;
      display: block;
      margin: 0 1rem 0.2rem; }
      .ProfileDropdown_drop-down-button .SVGIcon * {
        fill: #4A4A4A; }
    .ProfileDropdown_drop-down-button:hover, .ProfileDropdown_drop-down-button:focus {
      background-color: rgba(50, 171, 225, 0.25); }
    .ProfileDropdown_drop-down-button_inner {
      width: 100%;
      height: 3rem;
      padding: 0.25rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .ProfileDropdown_drop-down-button_inner span {
        text-align: left;
        flex-grow: 1; }
      .ProfileDropdown_drop-down-button_inner a {
        color: #4A4A4A; }

.LoadingClientData {
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.Account_top {
  background-color: #f3f3f3;
  padding: 2rem 0;
  margin-bottom: 2rem; }
  .Account_top h1 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    color: #101E2D;
    font-weight: 600; }

.Account_section-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .Account_section-title-row_title {
    font-size: 1.5rem; }

.Account_divider {
  height: 1.5rem;
  background-color: #f3f3f3; }

.Button {
  color: #FFF;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: #32ABE1;
  border: solid 1px #499FC7;
  padding: 0.375rem 1.75rem;
  position: relative; }
  .Button_secondary {
    color: #6c7888;
    background-color: #F4F3F4;
    border: solid 1px #D6DEE9;
    margin-right: 0.5rem; }
  .Button_link {
    background: transparent;
    border: none;
    color: #06B5FB;
    font-weight: 500;
    padding: 0.375rem 0.75rem;
    padding-left: 0; }
  .Button_md {
    font-size: 1rem;
    padding: 0.625rem 1.875rem; }
  .Button_download-csv {
    font-size: 0.75rem;
    height: 2.625rem;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    white-space: nowrap;
    padding: 0; }
  .Button_loading {
    color: transparent; }
    .Button_loading_wrapper {
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 1.75rem;
      height: 1.75rem; }
    .Button_loading_svg {
      animation: Button_rotate_animation 2s linear infinite;
      transform-origin: center center;
      width: 100%;
      height: 100%; }
    .Button_loading_path {
      stroke: #FFF;
      stroke-dasharray: 150,200;
      stroke-dashoffset: -10;
      stroke-width: 4px;
      animation: Button_dash_animation 1.5s ease-in-out infinite;
      stroke-linecap: round;
      fill: none; }
  .Button:disabled {
    opacity: 0.7; }

@keyframes Button_rotate_animation {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes Button_dash_animation {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124; } }

.InsightsNavBar_ul {
  list-style: none;
  padding: 0;
  margin: 2rem 0 1rem 2rem; }

.InsightsNavBar_li {
  display: inline-block;
  user-select: none;
  margin: 0 2rem 2rem 0;
  position: relative;
  font-size: 0.875rem; }

.InsightsNavBar_link {
  font-weight: 600;
  color: #697689;
  transition: color 0.2s; }
  .InsightsNavBar_link:after {
    content: " ";
    display: block;
    background-color: #32ABE1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.125rem;
    transform: scaleX(0);
    opacity: 0;
    transition: all 0.2s; }
  .InsightsNavBar_link:hover, .InsightsNavBar_link_selected {
    color: #32ABE1;
    text-decoration: none; }
    .InsightsNavBar_link:hover:after, .InsightsNavBar_link_selected:after {
      transform: scaleX(1);
      opacity: 1; }

.InsightsNavBar_disabled-link {
  color: #697689;
  opacity: 0.5; }

.Organisations {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #697689;
  font-size: .75rem;
  letter-spacing: .00063rem; }
  .Organisations_inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-gap: 0.5rem 2rem; }
  .Organisations_title {
    grid-column: 1;
    grid-row: 1;
    padding-top: 2rem;
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 2rem; }
    .Organisations_title h6 {
      color: black !important;
      font-size: 1.125rem !important;
      margin-bottom: 0px !important;
      font-weight: 600 !important;
      font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important; }
  .Organisations_categorySelect {
    padding-left: 1rem;
    margin-top: 1rem;
    grid-column: 1;
    grid-row: 2; }
    .Organisations_categorySelect_additional-button {
      cursor: pointer; }
      .Organisations_categorySelect_additional-button :hover {
        color: blue; }
  .Organisations_ChangeButton {
    padding-top: 2rem;
    grid-column: 2;
    grid-row: 1; }
    .Organisations_ChangeButton div {
      border-radius: 50%;
      width: 42px;
      height: 42px;
      padding: 9px;
      float: right;
      margin-right: 2rem;
      cursor: pointer; }
      .Organisations_ChangeButton div:hover {
        background-color: rgba(245, 0, 87, 0.04); }
  .Organisations table {
    width: 100%; }
  .Organisations_tableArea {
    grid-column: 1/3;
    grid-row: 3/4; }
  .Organisations_tableRowHead {
    border-bottom: 1px solid #e0e0e0;
    color: black !important;
    font-size: 16px !important;
    font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important; }
    .Organisations_tableRowHead_firstCell {
      text-align: center; }
    .Organisations_tableRowHead_nthCell {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left; }
  .Organisations_tableRowBody {
    border-bottom: 1px solid #e0e0e0; }
    .Organisations_tableRowBody:hover {
      background-color: rgba(0, 0, 0, 0.04) !important; }
    .Organisations_tableRowBody_selected {
      background-color: rgba(0, 0, 0, 0.04);
      border-bottom: 1px solid #e0e0e0; }
    .Organisations_tableRowBody_firstCell {
      text-align: center; }
    .Organisations_tableRowBody_nthCell {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left; }
  .Organisations_tableRowFoot {
    border-bottom: 1px solid #e0e0e0;
    color: #697689; }
    .Organisations_tableRowFoot_firstCell {
      text-align: center; }
    .Organisations_tableRowFoot_nthCell {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left; }
  .Organisations_badge_ON {
    background-color: #4caf50 !important;
    display: inline-block;
    font-weight: 400;
    padding: 3px 8px;
    line-height: inherit;
    margin-bottom: 6px;
    border-radius: .125rem;
    text-align: center;
    color: white !important;
    font-size: 75%; }
  .Organisations_badge_OFF {
    background-color: #697689 !important;
    display: inline-block;
    font-weight: 400;
    padding: 3px 8px;
    line-height: inherit;
    margin-bottom: 6px;
    border-radius: .125rem;
    text-align: center;
    color: white !important;
    font-size: 75%; }
  .Organisations_select {
    width: 12rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
    display: inline-block; }

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #32abe1 !important; }

.field {
  padding: 6px 0px;
  box-sizing: border-box;
  display: block;
  font-size: 0.9rem; }
  .field-name {
    font-size: 1em;
    color: rgba(10, 10, 10, 0.6);
    font-weight: 500;
    margin: 0px;
    padding: 0px; }
  .field-value {
    margin: 5px 0px;
    padding: 0px;
    font-weight: 400;
    font-size: 1em;
    color: #0a0a0a; }

.SDKItem {
  padding: 1rem; }
  .SDKItem_inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-gap: 0.5rem 2rem;
    width: 100%; }
    .SDKItem_inner_iOS-title {
      grid-column: 1;
      grid-row: 1;
      text-align: center; }
      .SDKItem_inner_iOS-title h4 {
        font-family: 'Raleway', sans-serif;
        font-size: 20.2px;
        color: #1C1C1C;
        letter-spacing: 0.25px;
        font-weight: 600; }
    .SDKItem_inner_android-title {
      grid-column: 2;
      grid-row: 1;
      text-align: center; }
      .SDKItem_inner_android-title h4 {
        font-family: 'Raleway', sans-serif;
        font-size: 20.2px;
        color: #1C1C1C;
        letter-spacing: 0.25px;
        font-weight: 600; }
    .SDKItem_inner_title-bottomline {
      grid-column: 1 / 3;
      grid-row: 2;
      height: .5px;
      border: 1px #d6dee9 solid; }
    .SDKItem_inner_iOS-content {
      grid-column: 1;
      grid-row: 3;
      border-right: 1px #d6dee9 solid; }
      .SDKItem_inner_iOS-content_item {
        padding: 1rem;
        margin-bottom: 1rem; }
    .SDKItem_inner_android-content {
      grid-column: 2;
      grid-row: 3; }
      .SDKItem_inner_android-content_item {
        padding: 1rem;
        margin-bottom: 1rem; }
  .SDKItem_modal {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-gap: 0.5rem 2rem;
    width: 100%; }
    .SDKItem_modal_titleArea {
      grid-column: 1 / 3;
      grid-row: 1; }
    .SDKItem_modal_buttonArea {
      grid-column: 1 / 3; }
    .SDKItem_modal h3 {
      padding-bottom: .5rem; }

.MuiTreeItem-label {
  background-color: transparent !important; }

.MuiTypography-body1 {
  font-weight: inherit !important; }

.UserAccounts {
  padding: 2rem 0 2.5rem; }
  .UserAccounts_table {
    width: 100%; }
    .UserAccounts_table thead {
      font-size: 1.125rem;
      border-bottom: solid 1px #979797; }
      .UserAccounts_table thead th {
        font-weight: 600; }
    .UserAccounts_table td, .UserAccounts_table th {
      padding: 0.875rem 0.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 20%; }
    .UserAccounts_table tbody tr {
      border-bottom: solid 1px #D6DEE9; }
    .UserAccounts_table tbody td {
      color: #697689;
      font-weight: 0.875rem; }
      .UserAccounts_table tbody td:last-child {
        text-align: right; }
  .UserAccounts_icon-button {
    width: 2rem;
    height: 2rem;
    border: none;
    background: transparent;
    padding: 0.325rem; }
    .UserAccounts_icon-button .SVGIcon {
      display: block;
      width: 1.25rem;
      height: 1.25rem; }
      .UserAccounts_icon-button .SVGIcon * {
        fill: #101010; }

.UserAccountsEditRow td {
  vertical-align: top; }

.UserAccountsEditRow_input {
  color: #697689;
  display: block;
  font-weight: 0.875rem;
  border: solid 1px #D8D8D8;
  border-radius: 0.25rem;
  padding: 0.25rem 0.75rem;
  width: 90%; }

.UserAccountsEditRow_error-message {
  color: #ff2c2c;
  padding: 0.875rem 0.5rem 0;
  margin: 0; }

.UserAccountsEditRow_checkbox-label {
  font-size: 0.75rem;
  margin: 0 1rem 0 0;
  user-select: none;
  transform: translateY(-0.5rem); }

.UserAccountsEditRow_checkbox {
  display: block;
  margin: 0 auto; }

.PersonalDetails {
  padding-bottom: 2.5rem; }
  .PersonalDetails .PersonalDetails_role {
    grid-column: 3;
    grid-row: 1;
    text-align: right; }
  .PersonalDetails .PersonalDetails_role_inner {
    display: inline-block;
    background-color: #f3f3f3;
    color: #697689;
    box-shadow: 0 1px 17px 4px rgba(0, 0, 0, 0.03);
    padding: 0 2rem;
    border-radius: 2rem;
    height: 2rem;
    line-height: 2rem; }
  .PersonalDetails_inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-gap: 0.5rem 2rem;
    padding: 1rem; }
  .PersonalDetails .Loading {
    grid-column: 1 / 4;
    grid-row: 1 / 5; }
  .PersonalDetails_first-name-input {
    grid-column: 1;
    grid-row: 1; }
  .PersonalDetails_last-name-input {
    grid-column: 2;
    grid-row: 1; }
  .PersonalDetails_email-input {
    grid-column: 1;
    grid-row: 2; }
  .PersonalDetails_current-input {
    grid-column: 1;
    grid-row: 3; }
  .PersonalDetails_new-input {
    grid-column: 2;
    grid-row: 3; }
  .PersonalDetails_confirm-input {
    grid-column: 3;
    grid-row: 3; }
  .PersonalDetails_change-password-button-wrapper {
    grid-column: 1;
    grid-row: 4; }
  .PersonalDetails_cancel-save-button-wrapper {
    grid-column: 3;
    grid-row: 4;
    text-align: right; }

.PersonalDetailsInput {
  margin-bottom: 0.5rem; }
  .PersonalDetailsInput_label {
    display: block; }
    .PersonalDetailsInput_label_text {
      display: block;
      font-size: 1rem;
      font-weight: 600;
      color: #333; }
  .PersonalDetailsInput_value, .PersonalDetailsInput_input {
    color: #697689;
    display: block;
    font-weight: 0.875rem;
    width: 90%; }
  .PersonalDetailsInput_input {
    border: solid 1px #D8D8D8;
    border-radius: 0.25rem;
    padding: 0.25rem 0.75rem; }
  .PersonalDetailsInput_error-message {
    color: #ff2c2c; }

.SelectionNotAvailable {
  text-align: center;
  padding: 3rem;
  color: #333;
  display: table;
  height: 100%; }
  .SelectionNotAvailable_title {
    font-weight: 400;
    font-size: 1.75rem; }
  .SelectionNotAvailable_cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
  .SelectionNotAvailable_subtitle {
    font-weight: 600;
    font-size: 1.75rem; }
  .SelectionNotAvailable .SVGIcon {
    width: 3.5rem;
    height: 3.5rem;
    display: block;
    margin: 2rem auto; }
    .SelectionNotAvailable .SVGIcon * {
      fill: #444; }
